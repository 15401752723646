import { apiSlice } from "./apiSlice"
import { apiRoutes } from "../../config/api"

export const processService = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllActiveProcess: builder.query({
            query: (params: any) => {
                const {roleId, queryParams} = params
              return {
              url: `${apiRoutes.GETALLPROCESS}/${roleId}`,
              params: queryParams,
              method: "GET",
            }},
          }),
        getAllExecuteProcess: builder.query({
            query: (params: any) => {
                const {roleId, queryParams} = params;
              return {
              url: `${apiRoutes.GETALLPROCESS}/${roleId}`,
              params: queryParams,
              method: "GET",
            }},
          }),

        stopActiveProcess: builder.mutation({
            query: (formData: any) => {
                return {
                  url: `${apiRoutes.CANCEL_SITEBUTTON}`,
                  method: "POST",
                  body: formData,
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                  },
                };
            },
          }),

          startProcess: builder.mutation({
            query: (formData: any) => {
                return {
                  url: `${apiRoutes.START_PROCESS}`,
                  method: "POST",
                  body: formData,
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                  },
                };
            },
          }),

          pauseProcess: builder.mutation({
            query: (formData: any) => {
              return {
                url: `${apiRoutes.PAUSE_PROCESS}`,
                method: "POST",
                body: formData,
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              };
            },
          }),
      
          resumeProcess: builder.mutation({
            query: (formData: any) => {
              return {
                url: `${apiRoutes.RESUME_PROCESS}`,
                method: "POST",
                body: formData,
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                },
              };
            },
          }),
    })
})

export const {
            useGetAllActiveProcessQuery, 
            useGetAllExecuteProcessQuery,
            useStopActiveProcessMutation,
            useStartProcessMutation,
            usePauseProcessMutation,
            useResumeProcessMutation 
} = processService