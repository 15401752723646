/* eslint react-hooks/exhaustive-deps: 0 */

import { Col, Form, Row } from "antd";
import { useEffect } from "react";
import { UIInput } from "../../common/input";
import { UISelectInput } from "../../common/selectInput";

export const  SiteDeviceForm = (props:any) => {

    let {
        onModalSubmit,
        form,
        formValues,
        setFormValues,
        showAlert,
        siteOptions,
        deviceOptions,
        zoneData,
        setZoneData,
        id
      } = props;

const handleDeviceChange =(value:any, option:any ) => {
  setFormValues({...formValues, deviceId:value, deviceName:option?.children})
}


const handleChange =(value:any) => {
  setFormValues({...formValues, siteId:value, deviceId:"", deviceName:""})
  form.setFieldsValue({deviceId:null})
  setZoneData({})
}

const handleZone =(value:any) => {
  setFormValues({...formValues, zoneId:value})
}

useEffect(()=>{
  if(zoneData){
    setFormValues({...formValues,siteName:zoneData?.siteName, zoneName:zoneData?.zoneName})
  }
},[zoneData])

  return (
    <div>
      <Form
      id={id}
      key={zoneData?.zoneName}
      form={form}
      className="modalForm"
      layout="vertical"
      onFinish={() => {
        onModalSubmit();
      }}>
        <Row>
            <Col span={24} className="siteFormHeader">
            Select fixed reader device from list
            </Col>
        </Row>
        <div className="errorMsg">{showAlert}</div>
        <Row>
          <Col span={24}>
            <UISelectInput
              label="Site"
              placeholder="Select Site"
              name="siteId"
              rules={[
                {
                  required: true,
                  message: "Please select Site",
                },
              ]}
              optionValue={siteOptions}
              value={formValues?.siteId}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="inputCss">
            <UISelectInput
              label="Device"
              placeholder="Select Device"
              name="deviceId"
              rules={[
                {
                  required: true,
                  message: "Please select Device",
                },
              ]}
              optionValue={deviceOptions}
              value={formValues?.deviceId}
              onChange={handleDeviceChange}
            />
          </Col>

          <Col span={24} className="inputCss">
          <UIInput
          label="Zone"
          name="zoneName"
          disabled
          defaultValue={zoneData?.zoneName}
          value={zoneData?.zoneName}
          onChange={handleZone}
          />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
