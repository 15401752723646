import { Routes, Route } from "react-router-dom";
import { Page } from "./config";
import UILayout from "../components/layouts";
import { ProtectedRoute } from "./ProtectedRoute";
import { ActiveProcess } from "../components/pages/activeProcess";
import Login from "../components/pages/auth";
import { DeviceTest } from "../components/pages/deviceTest";
import { ExecuteProcess } from "../components/pages/executeProcess";
import { ActivityLogs } from "../components/pages/activityLogs";


const UIRoutes = (props:any) => {
  return (
    <Routes>
      <Route element={<ProtectedRoute redirectPath={Page.LOGIN}/>}>
      <Route path="/" element={<UILayout />}>
        <Route index element={<ActiveProcess/>} />
        <Route path={Page.DEVICE_TEST} element={<DeviceTest/>} />
        <Route path={Page.EXECUTE_PROCESS} element={<ExecuteProcess/>} />
        <Route path={Page.HELP} element={""} />
        <Route path={Page.ACTIVITY_LOGS} element={<ActivityLogs/>} />
      </Route>
      </Route>
      <Route path={Page.LOGIN} element={<Login />} />
      {/* <Route path={Page.CREATE_PASSWORD} element={''} /> */}
    </Routes>
  );
};

export default UIRoutes;
