/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Form,
  Card,
  Row,
  Col,
  Spin,
} from "antd";
import "./style.css";
import { envTag, newCosmosCircle, scblackTextLogo } from "../../../assets/images/index";
import { UIInput, UIInputPassword } from "../../common/input";
import { UIbutton } from "../../common/button";
import {
    useLoginMutation,
} from "../../../redux/services/authApiSlice";
import { config } from "../../../config";
import { Page } from "../../../routes/config";
import { Regex } from "../../../config/constants";
import {authStateSelector, setUserData} from "../../../redux/features/auth/authSlice"
import { useDispatch, useSelector } from "react-redux";
import { setSessionExist } from "../../../redux/features/app/appSlice";

export const Login = () => {
  //states
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showAlert, setShowAlert] = useState(false)

  //API
  const [userLogin, userLoginData] = useLoginMutation();
  const dispatch = useDispatch()
  useEffect(() => {
    if (userLoginData?.isSuccess) {
      const {token, tenantId ,
        _id,
        userId,
        name,
        gender,
        imageUrl,
        username,
        password,
        email,
        contact,
        status,
        roleId,
        roleName,
        isLoggedIn = true
      } =
        userLoginData?.data?.result;
        let userInfo = {
          _id,
        userId,
        name,
        gender,
        imageUrl,
        username,
        password,
        email,
        contact,
        status,
        roleId,
        roleName
        }
      dispatch(setUserData({userInfo, token, tenantId, isLoggedIn}))
      dispatch(setSessionExist(true))
      window.location.href = Page.ACTIVE_PROCESS;
    }
  }, [userLoginData?.isSuccess]);

  //functions
  const onFinish = (values: any) => {
    userLogin(credentials);
  };
  const handleChange = (Event: any) => {
    setCredentials({
      ...credentials,
      [Event.target.name]: Event.target.value,
    });
  };


  
  useEffect(()=>{
    if (userLoginData?.isError) {
      setShowAlert(true)
    }
    return (()=>{
    setTimeout(() => {
      setShowAlert(false)
    }, 5000)})
  },[userLoginData?.isError])

 const showError = () => {
    if (userLoginData?.isError && showAlert) {
      return(
      <Row className="errorLogin">
        <Col span={24}>
          {showAlert &&
          <p className="errorMessageAlert">
         { userLoginData.error?.data?.error?.message}   
          </p>
    }
        </Col>
      </Row>
      )
    }
  };

  return (
    <div className="mainDiv">
      <Card className="Card" bordered={false}>
        <div className="envTag">
          <figure>
            <img data-testid="envTag" src={envTag} alt="env"/>
            <figcaption className="envText">{config.ENV}</figcaption>
          </figure>
        </div>
        <Row>
        <Col span={24} className="logoDiv ">
            <img
            className="cosmosCircle"
              data-testid="mainLogo"
              width="160px"
              src={newCosmosCircle}
              alt="the alt text"
            />
          </Col>
          <Col span={24} className="logoDiv ">
            <img
            className="smallLogo"
              data-testid="mainLogo"
              width="160px"
              src={scblackTextLogo}
              alt="the alt text"
            />
          </Col>
        </Row>
        <div className="cardDiv">
          <p data-testid="text" className="heading">
            Sign in to the Device Manager
          </p>
          <Form className="mainForm" layout="vertical" onFinish={onFinish}>
            <Row>
              <Col span={24}>
                <Row className="inputCss">
                  <Col span={24}>
                    <UIInput
                      label="Username"
                      placeholder="Enter Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your username",
                        },
                        {
                          pattern: new RegExp(/^\S{6,}$/),
                          message: "Please enter a valid username",
                        },
                      ]}
                      value={credentials.username}
                      onChange={handleChange}
                    ></UIInput>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row className="inputCss">
                  <Col span={24}>
                    <UIInputPassword
                      label="Password"
                      placeholder="Enter Password"
                      name="password"
                      type="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                        {
                          pattern: new RegExp(Regex.PASSWORD_VALID_LENGTH),
                          message: "Please enter a valid password",
                        },
                      ]}
                      value={credentials.password}
                      onChange={handleChange}
                    ></UIInputPassword>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="loginErrorMessageHeight">
            {showError()}
            </div>
            <Row className="signIn">
              <Col span={24}>
                <UIbutton type="info" className="signinButton" btnType="submit" size="xl">
                <div style={{ width: "20px", marginLeft: "-30px", marginRight: "10px" }}>
                  {userLoginData.isLoading && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 20,
                            color: "#fff",
                            marginRight: "10px",
                          }}
                          spin
                        />
                      }
                    />
                  )}
                  </div>
                  SIGN IN
                </UIbutton>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Login;
